import * as React from 'react';

// types
import { VacancyType } from '../../types';

// components
import {
    Anchor,
    Button,
    Container,
    Grid,
    Group,
    MantineTheme,
    Paper,
    Stack,
    Text,
    Title,
    createStyles,
    useMantineTheme
} from '@mantine/core';
import { FaChevronLeft } from 'react-icons/fa';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { renderQminoRichText } from "../../util/rich-text";

interface Props {
    vacancy: VacancyType;
}

const useStyles = createStyles((theme: MantineTheme) => ({
    // added css style with theme object to center align in smaller screens
    joboverzicht: {

        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(24px + 1vw)",
            display: 'flex',
            justifyContent: 'flex-start',
            paddingLeft: 50
        },
    },

    projectTitle: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(32px + 1vw)",
            textAlign: 'center',
            alignSelf: 'center',
            marginTop:50,
            lineHeight:1.4,
        }
    },

    projectDetail: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(32px + 1vw)",
            textAlign: 'center',
            alignSelf: 'center',
            marginTop:20,
            marginBottom:0
        }
    },

    imageAlign: {
        [theme.fn.smallerThan('md')]: {
            position: 'relative',
            top: '70px',
            width:600,
            maxWidth:700
        }
    },
    buttonFont: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(34px + 1vw)",
            marginTop: 60,
            marginBottom: '0 !important',
            minHeight: 90,
            height: 50
        }
    },
    textClass:{
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(36px + 1vw)",
            lineHeight:1.3,
            textAlign:'left',
            paddingRight: theme.spacing.xl,
            paddingLeft: theme.spacing.xl
        }
    }

}))

const VacancyContent = ({ vacancy }: Props) => {
    const { classes } = useStyles();
    const theme: MantineTheme = useMantineTheme();
    // const smallerThanMd = theme.breakpoints.md
    return (
        <div>
            <div
                style={{
                    backgroundColor: theme.colors.gray[1],
                }}
            >
                <Container py="3rem" size="lg">
                    <Anchor component={Link} to={'/jobs'} className={classes.joboverzicht}>
                        <Group spacing={10} py="md">
                            <FaChevronLeft size={12} />
                            Terug naar joboverzicht
                        </Group>
                    </Anchor>
                    <Grid justify="center" align="center">
                        <Grid.Col offsetXs={3} offsetMd={0} offsetSm={0} sm={0} xs={6} md={6}>
                            <Stack pl={20}>

                                <Title size="h2">
                                    <Text color="gray.8" weight={700} className={classes.projectTitle}>
                                        {vacancy.title}
                                    </Text>
                                </Title>
                                <Text color="gray.7" weight={500} mb={17} className={classes.projectDetail}>
                                    {vacancy.details}
                                </Text>
                            </Stack>
                        </Grid.Col>
                        <Grid.Col offsetMd={0} offset={1} span={6} className={classes.imageAlign}>
                            {/* <Center> */}
                            {
                                vacancy.image &&
                                <GatsbyImage image={vacancy.image.gatsbyImageData} alt="" />
                            }
                            {/* </Center> */}
                        </Grid.Col>
                    </Grid>
                </Container>
            </div>
            <Container
                sx={(theme) => ({
                    [theme.fn.smallerThan('sm')]: {
                        padding: 0,
                    },
                    [theme.fn.smallerThan('md')]: {
                        paddingTop: 50,
                    },
                })}>
                <Paper
                    my="4rem">
                    <Container size="xl" className={classes.projectTitle}>
                        {renderQminoRichText(vacancy.content, classes.textClass)}
                        <Button component="a" href={`mailto:hello@qmino.com?subject=VACATURE - ${vacancy.title}`} mt={"2rem"}
                            className={classes.buttonFont}>
                            Ik solliciteer
                        </Button>
                    </Container>
                </Paper>
            </Container>
        </div>
    );
};

export default VacancyContent;
