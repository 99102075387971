import * as React from 'react';
import {HeadProps, PageProps} from 'gatsby';

// components
import Layout from '../components/layout';
import OtherVacancies from '../components/vacancy-page/other-vacancies';
import VacancyContent from '../components/vacancy-page/vacancy-content';
import { VacancyType } from '../types';
import SEO from "../components/seo";

type PageContext = {
    previous: {
        slug: string;
        title: string;
    };
    next: {
        slug: string;
        title: string;
    };
    node: VacancyType;
};

const VacancyTemplate = ({ pageContext }: PageProps<{}, PageContext>) => {
    const {previous, node, next} = pageContext;

    return (
        <Layout>
            <VacancyContent vacancy={node}/>
            <OtherVacancies previous={previous} next={next}/>
        </Layout>
    );
};

export const Head = ({pageContext}: HeadProps) => (
    <SEO title={(pageContext as PageContext).node.title}/>
);

export default VacancyTemplate;
