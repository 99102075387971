import * as React from "react";

// components
import { Button, Center, Container, Flex, Group, MantineTheme, Stack, Text, createStyles, useMantineTheme } from "@mantine/core";
import { Link } from "gatsby";

// icons
import chevronLeftIcon from '../../images/icons/cheveron-left.svg';
import chevronRightIcon from '../../images/icons/cheveron-right.svg';

interface Props {
	previous: {
		slug: string;
		title: string;
		details: string;
	};
	next: {
		slug: string;
		title: string;
		details: string;
	};
}

const useStyles = createStyles((theme: MantineTheme) => ({
	// added css style with theme object to center align in smaller screens
	container: {
		[theme.fn.smallerThan('md')]: {
			textAlign: 'center',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			marginTop:0
		},
	},

	icon: {
		[theme.fn.smallerThan('md')]:{
			marginTop:0,
			marginBottom:20,
			paddingTop:0,
			paddingBottom:30,
			fontSize: "calc(24px + 1vw)",
		}
	}
}))

const OtherVacancies = ({ previous, next }: Props) => {
	const { classes } = useStyles();
	const theme: MantineTheme = useMantineTheme();

	return (
		<Container py="xl" size="lg" className={classes.container}>
			<Group
				position="apart"
				sx={(theme) => ({
					[theme.fn.smallerThan("sm")]: {
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					},
				})}
			>
				<div>
					{previous && (
						<Button
							variant="white"
							color="gray"
							sx={(theme) => ({
								boxShadow: "none",
								"&:hover": {
									boxShadow: "none",
								},
							})}
							component={Link}
							to={`/jobs/${previous.slug}`}
						>
							<Flex className={classes.icon}>
								<Center>
									<img src={chevronLeftIcon} alt={"indicate-left"} width={15} />
								</Center>
								<Stack p="lg">
									<Flex direction="column" align="flex-start">
										{previous.title}
										<Text tt="lowercase" fz="sm" weight={400}>
											{previous.details}
										</Text>
									</Flex>
								</Stack>
							</Flex>
						</Button>
					)}
				</div>
				<div>
					{next && (
						<Button
							variant="white"
							color="gray"
							sx={(theme) => ({
								boxShadow: "none",
								"&:hover": {
									boxShadow: "none",
								},
							})}
							component={Link}
							to={`/jobs/${next.slug}`}
						>
							<Flex>
								<Stack p="lg" className={classes.icon}>
									<Flex direction="column" align="flex-end">
										{next.title}
										<Text tt="lowercase" fz="sm" weight={400}>
											{next.details}
										</Text>
									</Flex>
								</Stack>
								<Center className={classes.icon}>
									<img src={chevronRightIcon} alt={"indicate-right"} width={15} />
								</Center>
							</Flex>
						</Button>
					)}
				</div>
			</Group>
		</Container>
	);
};

export default OtherVacancies;
